import React, { useState } from "react";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography,
    Container,
    Box,
    ThemeProvider,
    createTheme,
    IconButton,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import StockCard from "../../Dashboard/Components/StockCard";
import InstructionsModal from "../Components/InstructionModal";
import GenerateReports from "../Components/GenerateReports";
import DiamondActions from "../Components/DiamondActions";
import axios from "axios"; // Import axios
import StockDataCard from "../../../Component/StockDataCard";
import SkeletonData from "../../../Component/SkeletonData";
import CustomSnackBar, { snackbarType } from "../../../Component/CustomSnackBar";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // Import separately if not already in jsPDF


import {
    PictureAsPdf as PdfIcon,
    TableChart as ExcelIcon,

} from '@mui/icons-material';

const Report: React.FC = () => {
    // Initialize with one object in the array
    const [formData, setFormData] = useState<any>([]);
    // const [resultformData, setResultFormData] = useState<any>([]);
    const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false)
    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });


    const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: value,
        };
        setFormData(updatedFormData);
    };



    const handleDownloadPDF = () => {
        if (formData && formData.length > 0) {
            const doc = new jsPDF();


            doc.setFontSize(20);
            doc.setTextColor(0, 51, 102);
            doc.text('Diamond Stock Report', doc.internal.pageSize.width / 2, 20, { align: 'center' });


            doc.setFontSize(10);
            doc.setTextColor(100);
            const date = new Date().toLocaleDateString();
            doc.text(`Generated on: ${date}`, doc.internal.pageSize.width - 10, 30, { align: 'right' });


            doc.setDrawColor(220);
            doc.line(10, 35, doc.internal.pageSize.width - 10, 35);


            const excludedColumns = ['_id', 'user_email', 'id', '__v', 'in_stock'];
            const tableColumnHeaders = Object.keys(formData[0]).filter(key => !excludedColumns.includes(key));
            const tableRows = formData.map((data: any) =>
                tableColumnHeaders.map((key) => data[key] || 'N/A')
            );


            autoTable(doc, {
                head: [tableColumnHeaders],
                body: tableRows,
                startY: 40,
                theme: 'grid',
                styles: {
                    fontSize: 8,
                    cellPadding: 1,
                    overflow: 'linebreak',
                    halign: 'center',
                },
                headStyles: {
                    fillColor: [0, 51, 102],
                    textColor: 255,
                    fontStyle: 'bold',
                },
                alternateRowStyles: {
                    fillColor: [240, 240, 240],
                },
                margin: { top: 40, left: 10, right: 10 },
            });


            const pageCount = doc.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.setFontSize(8);
                doc.setTextColor(100);
                doc.text(
                    `Page ${i} of ${pageCount}`,
                    doc.internal.pageSize.width / 2,
                    doc.internal.pageSize.height - 10,
                    { align: 'center' }
                );
            }


            doc.save('Diamond_Stock_Report.pdf');
        } else {
            setOnSuccess({
                success: true,
                message: "No data available to export.",
                variant: 'error',
            });
        }
    };

    const handleDownloadExcel = () => {
        if (formData.length > 0) {

            const excludedColumns = ['_id', 'user_email', 'id', '__v', 'in_stock'];


            const filteredData = formData.map((item: any) => {
                const filteredItem: { [key: string]: any } = {};
                Object.keys(item).forEach(key => {
                    if (!excludedColumns.includes(key)) {
                        filteredItem[key] = item[key];
                    }
                });
                return filteredItem;
            });


            const worksheet = XLSX.utils.json_to_sheet(filteredData);


            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Diamond Stock");


            XLSX.writeFile(workbook, 'Diamond_Stock_Report.xlsx');
        } else {
            setOnSuccess({ success: true, message: "No data available to export.", variant: 'error' });
        }
    };



    const handleSearch = async () => {
        try {


            setIsFilterLoading(true)

            const response = await axios.post("/stock/view-report", formData);
            console.log("status is ", response.status)
            if (response.status === 200) {
                setOnSuccess({ message: "Data fetched successfully!", success: true, variant: "success" });
            }

            console.log("Response from backend:", response.data);
            setFormData(response.data)
        } catch (error: any) {
            if (error.status === 400 || error.status === 500) {
                setFormData([])

                setOnSuccess({ message: error.response.data.message, success: true, variant: "error" });

            }
            console.error("Error submitting form:", error.status);
        }
        finally {
            setIsFilterLoading(false)

        }
    };
    const handleReportData = (data: any) => {
        setFormData(data);
        setIsFilterLoading(false);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: "#1976d2",
            },
            secondary: {
                main: "#dc004e",
            },
            background: {
                default: "#f5f5f5",
            },
        },
        typography: {
            h4: {
                fontWeight: 600,
            },
            h6: {
                fontWeight: 600,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 8,
                        textTransform: "none",
                        fontWeight: 600,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 12,
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />
                <Paper elevation={3} sx={{ p: 2, bgcolor: "background.default" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="h4" color="primary">
                                    Stock Overview
                                </Typography>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <IconButton color="primary" aria-label="refresh">
                                        <RefreshIcon />
                                    </IconButton>
                                    <InstructionsModal />
                                </Box>
                            </Box>
                            <StockCard />
                            <DiamondActions onActionGenerated={handleReportData} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Paper elevation={2} sx={{ p: 3, height: "100%", bgcolor: "white" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom color="primary">
                                            Select Date Range
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="From Date"
                                                    type="date"
                                                    name="fromDate"
                                                    value={formData.fromDate}
                                                    onChange={(e) => handleInputChange(0, e)}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <CalendarTodayIcon color="action" sx={{ mr: 1 }} />
                                                        ),
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="To Date"
                                                    type="date"
                                                    name="toDate"
                                                    value={formData.toDate}
                                                    onChange={(e) => handleInputChange(0, e)}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <CalendarTodayIcon color="action" sx={{ mr: 1 }} />
                                                        ),
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <GenerateReports onReportGenerated={handleReportData} />

                                        {/* Download buttons for PDF and Excel */}
                                        {formData.length > 0 && <Box sx={{ mt: 2 }}>
                                            <Box sx={{ mt: 2 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            variant="outlined"
                                                            fullWidth
                                                            startIcon={<PdfIcon />}
                                                            onClick={handleDownloadPDF}
                                                            sx={{
                                                                py: 1.5,
                                                                color: 'error.main',
                                                                borderColor: 'error.main',
                                                                '&:hover': {
                                                                    color: "white",
                                                                    backgroundColor: 'error.light',
                                                                    borderColor: 'error.dark',
                                                                },
                                                            }}
                                                        >
                                                            Download PDF
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            variant="outlined"
                                                            fullWidth
                                                            startIcon={<ExcelIcon />}
                                                            onClick={handleDownloadExcel}
                                                            sx={{
                                                                py: 1.5,
                                                                color: 'success.main',
                                                                borderColor: 'success.main',
                                                                '&:hover': {
                                                                    color: "white",
                                                                    backgroundColor: 'success.light',
                                                                    borderColor: 'success.dark',
                                                                },
                                                            }}
                                                        >
                                                            Download Excel
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>}
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" gutterBottom color="primary">
                                            Search Diamonds
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Search by Stock ID"
                                                    fullWidth
                                                    name="stockId"
                                                    value={formData.stockId}
                                                    onChange={(e) => handleInputChange(0, e)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Search by Certificate ID"
                                                    fullWidth
                                                    name="certificateId"
                                                    value={formData.certificateId}
                                                    onChange={(e) => handleInputChange(0, e)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Search by Party Name"
                                                    fullWidth
                                                    name="partyName"
                                                    value={formData.partyName}
                                                    onChange={(e) => handleInputChange(0, e)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Search by Invoice Number"
                                                    fullWidth
                                                    name="invoiceNumber"
                                                    value={formData.invoiceNumber}
                                                    onChange={(e) => handleInputChange(0, e)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    startIcon={<SearchIcon />}
                                                    onClick={handleSearch}
                                                    sx={{ py: 1.5 }}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
                {isFilterLoading ? (
                    Array.from({ length: 5 }).map((_, index) => (
                        <SkeletonData key={index} />
                    ))
                ) : formData ? (
                    formData.map((data: any, index: any) => (
                        <Box sx={{ mt: 3 }}>
                            <StockDataCard key={index} stockData={data} />
                        </Box>
                    ))
                ) : (
                    <>
                        {setOnSuccess({ success: true, message: "No data available.", variant: 'error' })}
                    </>
                )}
            </Container>
        </ThemeProvider>

    );
};

export default Report;
