import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import axios from "axios"; // Make sure to install axios
import SkeletonComponent from "../../../Component/SkeletonComponent";
import SkeletonData from "../../../Component/SkeletonData";
import CustomSnackBar, { snackbarType } from "../../../Component/CustomSnackBar";
interface GenerateReportsProps {
    onReportGenerated: (data: any) => void;  // Define the callback prop for data transfer
}
const GenerateReports: React.FC<GenerateReportsProps> = ({ onReportGenerated }) => {
    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });
    // Function to handle API requests
    const fetchReport = async (period: string) => {
        try {
            const response = await axios.get(`/stock/view-periodreport?period=${period}`);
            if (response.status === 200) {
                onReportGenerated(response.data)
                setOnSuccess({ message: "Data fetched successfully!", success: true, variant: "success" });

            }


        } catch (error: any) {
            if (error.status === 400 || error.status === 500) {

                onReportGenerated([])
                setOnSuccess({ message: error.response.data.message, success: true, variant: "error" });
                console.error(`Error fetching ${period} report:`, error);

            }
        }
    };


    const handleDailyReportClick = () => {

        fetchReport("daily");
    };

    const handleQuarterlyReportClick = () => {

        fetchReport("quarterly");
    };

    const handleYearlyReportClick = () => {

        fetchReport("yearly");
    };

    return (
        <Grid item >
            <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />
            <Typography variant="h6" gutterBottom color="primary">
                Generate Reports
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<BarChartIcon />}
                        sx={{ py: 1.5 }}
                        onClick={handleDailyReportClick}
                    >
                        Daily Reports
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        startIcon={<TrendingUpIcon />}
                        sx={{ py: 1.5 }}
                        onClick={handleQuarterlyReportClick}
                    >
                        Quarterly Reports
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        startIcon={<ShowChartIcon />}
                        sx={{ py: 1.5 }}
                        onClick={handleYearlyReportClick}
                    >
                        Yearly Reports
                    </Button>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default GenerateReports;
