import { FormControl, Grid, TextField, FormLabel } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useLocation } from 'react-router-dom';
import { PurchaseContext } from '../Modules/Purchase/Context/purchaseProviderContext';

export default function StockNumber() {
    const urllocation = useLocation();
    const { filterFormState, filterFormDispatch } = useContext(PurchaseContext);

    const [filterFormData, setFilterFormData] = useState<any>(filterFormState);
    const user = useSelector((state: RootState) => state.user.data);
    const [newStockId, setNewStockId] = useState<string>('');

    // useEffect(() => {
    // if (user?.last_stock_id) {
    const numericPart = user?.last_stock_id && parseInt(user?.last_stock_id.replace(/\D/g, '')) || 0;
    const incrementedNumericPart = numericPart + 1;
    const updatedStockId = user?.last_stock_id && `${user.last_stock_id.replace(/\d+/g, '')}${incrementedNumericPart}`;



    return (
        <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 2, borderRadius: 2, mt: urllocation.pathname === "/purchase" ? 10 : 8 }}>
            <FormLabel sx={{ mb: 2 }}>Stock Information</FormLabel>
            <Grid container spacing={2} mb={2}>
                {urllocation.pathname === "/purchase" && <>
                    <Grid item xs={6}>
                        <TextField
                            size="small"

                            sx={{ backgroundColor: "white", borderRadius: 2 }}
                            fullWidth
                            label="Last Stock ID"
                            type="text"
                            defaultValue={user?.last_stock_id}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white", borderRadius: 2 }}
                            fullWidth
                            label="Current Stock ID"
                            type="text"
                            value={updatedStockId}
                        />
                    </Grid>
                    {/* <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white", borderRadius: 2 }}
                            fullWidth
                            label="Stock Number"    
                            type="text"
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white", borderRadius: 2 }}
                            fullWidth
                            label="Certificate ID"

                            type="text"
                        />
                    </Grid>
                </>}

                {urllocation.pathname === "/stock" && <>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white", borderRadius: 2 }}
                            fullWidth
                            label="Stock ID - (STCK-XXXX)"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            sx={{ backgroundColor: "white", borderRadius: 2 }}
                            fullWidth
                            label="Certificate ID"
                            type="text"
                        />
                    </Grid>
                </>}
            </Grid>
        </FormControl>
    );
}
