import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import "./index.css"
import axios from 'axios';
const root = createRoot(
  document.getElementById('root') as HTMLElement
);

// axios.defaults.baseURL = 'http://localhost:4001';
// axios.defaults.baseURL = 'https://superbdiamonds.in';
axios.defaults.baseURL = 'https://superbdiamonds-api-production-f521.up.railway.app';
// axios.defaults.baseURL = 'https://superbapi-production-da8f.up.railway.app';
axios.defaults.withCredentials = true

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
