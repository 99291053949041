import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
    Grid,
    FormControl,
    FormLabel,
    Box,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    TextField,
    ToggleButtonGroup,
    ToggleButton,
    MenuItem,
    InputLabel,
    Select,
    SelectChangeEvent,
    IconButton,
    Divider,
    AccordionSummary,
    Accordion,
    AccordionDetails,
    Radio,
    RadioGroup,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import StockNumber from './StockNumber';
import { buttonSizes, LabReports, diamondTradingCountries, Intensity, shapes, colors, clarities, finishes, fancycolor, Quality, Fluorescence, tableSpec } from '../Constants/FilterFormItems';
import { DiamondDataGrid } from './DiamondDataGrid';
import { useLocation } from 'react-router-dom';
import { PurchaseContext } from '../Modules/Purchase/Context/purchaseProviderContext';
import { Color } from '../Theme/Color';
import AddIcon from '@mui/icons-material/Add';
import CustomSnackBar, { snackbarType } from './CustomSnackBar';
import StockCard from '../Modules/Dashboard/Components/StockCard';
import StockDataCard from './StockDataCard';
import LoanCard from './LoanCard';
import SkeletonData from './SkeletonData';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
// import { API_BASE_URL } from '../backend';

const FilterForm: React.FC = () => {

    const { filterFormState, filterFormDispatch } = useContext(PurchaseContext);
    const [filterFormData, setFilterFormData] = useState<any>(filterFormState);
    let urllocation = useLocation();
    const purchasepath = urllocation.pathname === '/purchase'
    const stockpath = urllocation.pathname === '/stock'
    const [showExtraButtons, setShowExtraButtons] = useState(false);
    const [fancyColorsEnabled, setFancyColorsEnabled] = useState(false);
    const [showAllShapes, setShowAllShapes] = useState<boolean>(false);
    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });
    const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false)
    const user = useSelector((state: RootState) => state.user.data)


    useEffect(() => {
        setFilterFormData(filterFormState);
    }, [filterFormState]);


    const handleRap = async () => {
        try {
            const shapeParam = filterFormData && filterFormData.shape
                ? (filterFormData.shape === 'Round' ? 'BR' : filterFormData.shape)
                : undefined;

            const payload = {
                shape: shapeParam,
                quality: filterFormData?.clarity,
                color: filterFormData?.color,
                size_start: filterFormData?.size_start,
                size_end: filterFormData?.size_end
            };

            const response = await axios.post("/rap/view-rap", payload);


            if (response.status == 200) {
                setOnSuccess({ message: "Rap set successfully!", success: true, variant: "success" });
            }




            setFilterFormData((filterFormData: any) => {
                const updatedFormData = {
                    ...filterFormData,
                    price: response?.data?.price ? parseFloat(response.data.price) : ''
                };
                filterFormDispatch({ type: 'SET_FILTER_FORM', payload: updatedFormData });
                return updatedFormData;
            });

        } catch (error: any) {

            if (error.response.status === 400) {
                setOnSuccess({ message: error.response.data.message, success: true, variant: "info" });

            }
            else {
                setOnSuccess({ message: "Error fetching rap", success: true, variant: "error" });

            }



            setFilterFormData((filterFormData: any) => {
                const updatedFormData = {
                    ...filterFormData,
                    price: ''
                };
                filterFormDispatch({ type: 'SET_FILTER_FORM', payload: updatedFormData });
                return updatedFormData;
            });
        }
    }





    const handleSizeStartChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        setFilterFormData((filterFormData: any) => ({
            ...filterFormData,
            size_start: value || ''
        }));
    };
    const handleSizeEndChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFilterFormData((filterFormData: any) => ({
            ...filterFormData,
            size_end: value || ''
        }));
    };
    const handleIntensity = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setFilterFormData((prevState: any) => ({
            ...prevState,
            intensity: value
        }));
    };
    const handleOvertone = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setFilterFormData((prevState: any) => ({
            ...prevState,
            overtone: value
        }));
    };
    const handleFancyColor = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setFilterFormData((prevState: any) => ({
            ...prevState,
            fancy_color: value
        }));
    };
    const handleCutFrom = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setCutFrom(value)
        setFilterFormData((prevState: any) => ({
            ...prevState,
            cut_from: value
        }));
    };
    const handleCutTo = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setCutTo(value)
        setFilterFormData((prevState: any) => ({
            ...prevState,
            cut_to: value
        }));
    };
    const handlePolishFrom = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setPolishFrom(value)
        setFilterFormData((prevState: any) => ({
            ...prevState,
            polish_from: value
        }));
    };
    const handlePolishTo = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setPolishTo(value)
        setFilterFormData((prevState: any) => ({
            ...prevState,
            polish_to: value
        }));
    };
    const handleSymmetryFrom = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setSymmetryFrom(value)
        setFilterFormData((prevState: any) => ({
            ...prevState,
            symmetry_from: value
        }));
    };
    const handleSymmetryTo = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setSymmetryTo(value)
        setFilterFormData((prevState: any) => ({
            ...prevState,
            symmetry_to: value
        }));
    };
    const handleFluorescence = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setFilterFormData((prevState: any) => ({
            ...prevState,
            color_fluorescence: value
        }));
    };
    const handleWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFilterFormData((filterFormData: any) => ({
            ...filterFormData,
            weight: value ? parseFloat(value) : ''
        }));

    };
    const handleFancyColorsChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setFancyColorsEnabled(event.target.checked);
    };
    const handleColorChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string | string[]
    ) => {
        if (stockpath) {
            setFilterFormData((prevState: any) => ({
                ...prevState,
                color: Array.isArray(newValue) ? newValue : [newValue]
            }));
        } else if (purchasepath) {
            setFilterFormData((prevState: any) => ({
                ...prevState,
                color: newValue // Store as a single value or array depending on what newValue is
            }));
        }
    };



    const handleClarityChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string | string[]
    ) => {
        if (stockpath) {
            setFilterFormData((prevState: any) => ({
                ...prevState,
                clarity: Array.isArray(newValue) ? newValue : [newValue]
            }));
        } else if (purchasepath) {
            setFilterFormData((prevState: any) => ({
                ...prevState,
                clarity: newValue
            }));
        }
    };

    const handleColorFluorescence = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string | string[]
    ) => {
        if (stockpath) {
            setFilterFormData((prevState: any) => ({
                ...prevState,
                fluorescence: Array.isArray(newValue) ? newValue : [newValue]
            }));
        } else if (purchasepath) {
            setFilterFormData((prevState: any) => ({
                ...prevState,
                fluorescence: newValue // Store as a single value or array depending on what newValue is
            }));
        }
    };

    const handleButtonClick = (label: string) => {
        const [value1Str, value2Str] = label.split('-');

        setFilterFormData((filterFormData: any) => ({
            ...filterFormData,
            size_start: value1Str ? parseFloat(value1Str) : ''
        }));
        setFilterFormData((filterFormData: any) => ({
            ...filterFormData,
            size_end: value2Str ? parseFloat(value2Str) : ''
        }));

    };
    const handleLabChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string | string[]
    ) => {
        if (stockpath) {
            setFilterFormData((prevState: any) => ({
                ...prevState,
                lab_reports: Array.isArray(newValue) ? newValue : [newValue]
            }));
        } else if (purchasepath) {
            setFilterFormData((prevState: any) => ({
                ...prevState,
                lab_reports: newValue // Store as a single value or array depending on what newValue is
            }));
        }
    };


    const handleShapeClick = (shape: string) => {
        if (stockpath) {
            setFilterFormData((prevData: any) => {
                const currentShapes = prevData.shape || [];
                const newShapes = currentShapes.includes(shape)
                    ? currentShapes.filter((s: string) => s !== shape)
                    : [...currentShapes, shape];

                return {
                    ...prevData,
                    shape: newShapes
                };
            });
        } else if (purchasepath) {
            setFilterFormData((prevData: any) => ({
                ...prevData,
                shape
            }));
        }
    };


    const visibleShapes = showAllShapes ? shapes : shapes.slice(0, 12);

    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setFilterFormData((prevState: any) => ({
            ...prevState,
            country: value
        }));
    };


    const [fetchedData, setFetchedData] = useState<any>([])
    const handleSubmit = async () => {
        const filteredData = Object.fromEntries(
            Object.entries(filterFormData).filter(([_, value]) => {
                if (Array.isArray(value)) {
                    return value.length > 0;
                }
                return value !== null && value !== undefined && value !== '';
            })
        );
        try {
            setIsFilterLoading(true)
            const response = await axios.post("/stock/view-stock", filteredData);


            // const fetchedData = response.data;
            setFetchedData(response.data);




        } catch (error) {
            console.error('Error submitting form:', error);
        }
        finally {
            setIsFilterLoading(false)
        }
    };

    const initialButtons = buttonSizes.slice(0, 5);
    const extraButtons = buttonSizes.slice(5);
    const [cutFrom, setCutFrom] = useState<string>('')
    const [cutTo, setCutTo] = useState<string>('')
    const [polishFrom, setPolishFrom] = useState<string>('')
    const [polishTo, setPolishTo] = useState<string>('')
    const [symmetryFrom, setSymmetryFrom] = useState<string>('')
    const [symmetryTo, setSymmetryTo] = useState<string>('')


    const handleFinishChange = (finish: string) => {

        if (finish == "3X") {

            setCutFrom("Ideal")
            setCutTo("Excellent")
            setPolishFrom("Ideal")
            setPolishTo("Excellent")
            setSymmetryFrom("Ideal")
            setSymmetryTo("Excellent")
            setFilterFormData((prevState: any) => ({
                ...prevState,
                cut_from: "Ideal",
                cut_to: "Excellent",
                polish_from: "Ideal",
                polish_to: "Excellent",
                symmetry_from: "Ideal",
                symmetry_to: "Excellent",

            }));

        }
        else if (finish == "EX-") {
            setCutFrom("Excellent")
            setCutTo("Excellent")
            setPolishFrom("Excellent")
            setPolishTo("Very Good")
            setSymmetryFrom("Excellent")
            setSymmetryTo("Very Good")
            setFilterFormData((prevState: any) => ({
                ...prevState,
                cut_from: "Excellent",
                cut_to: "Excellent",
                polish_from: "Excellent",
                polish_to: "Very Good",
                symmetry_from: "Excellent",
                symmetry_to: "Very Good",

            }));

        }
        else if (finish == "VG+") {
            setCutFrom("Ideal")
            setCutTo("Very Good")
            setPolishFrom("Excellent")
            setPolishTo("Very Good")
            setSymmetryFrom("Excellent")
            setSymmetryTo("Very Good")
            setFilterFormData((prevState: any) => ({
                ...prevState,
                cut_from: "Ideal",
                cut_to: "Excellent",
                polish_from: "Excellent",
                polish_to: "Very Good",
                symmetry_from: "Excellent",
                symmetry_to: "Very Good",

            }));

        }
        else {
            setCutFrom("Very Good")
            setCutTo("Good")
            setPolishFrom("Very Good")
            setPolishTo("Good")
            setSymmetryFrom("Very Good")
            setSymmetryTo("Good")
            setFilterFormData((prevState: any) => ({
                ...prevState,
                cut_from: "Very Good",
                cut_to: "Good",
                polish_from: "Very Good",
                polish_to: "Good",
                symmetry_from: "Very Good",
                symmetry_to: "Good",

            }));

        }

    }

    const handleReset = () => {
        setFilterFormData({})
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />

                <Grid item xs={12}>
                    <StockNumber />
                </Grid>
                {/* Shape */}
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 2, borderRadius: 2 }}>
                        <FormLabel sx={{ mb: 1 }}>Shape</FormLabel>
                        <Grid container spacing={1}>
                            {visibleShapes.map((shape) => (
                                <Grid item xs={6} sm={3} md={2} lg={1} key={shape}>
                                    <div
                                        onClick={() => handleShapeClick(shape)}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            border: (filterFormData.shape?.includes(shape) ?? false) ? `2px solid ${Color.DARK_BLUE}` : '2px solid transparent',
                                            borderRadius: 3,
                                            padding: '5px',
                                        }}
                                    >
                                        <img src={`../DiamondTypes/${shape}.png`} alt={shape} style={{ maxWidth: '100%', height: '60px', borderRadius: 5 }} />
                                        <Typography variant="caption">{shape}</Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        <Box sx={{ m: 'auto' }} onClick={() => setShowAllShapes(!showAllShapes)}>
                            <IconButton>
                                {showAllShapes ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Box>
                    </FormControl>
                </Grid>
                {/* Size */}
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 2, borderRadius: 2 }}>
                        <FormLabel sx={{ mb: 2 }}>Size</FormLabel>

                        {/* Size input fields */}
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    size='small'
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    value={filterFormData.size_start || ""}
                                    fullWidth
                                    label="From"
                                    type="number"
                                    inputProps={{ min: 0, max: 10, step: 0.01 }}
                                    onChange={handleSizeStartChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    size='small'
                                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                                    value={filterFormData.size_end || ""}
                                    fullWidth
                                    label="To"
                                    type="number"
                                    inputProps={{ min: 0.0, max: 10, step: 0.01 }}
                                    onChange={handleSizeEndChange}
                                />
                            </Grid>
                        </Grid>

                        {/* Initial buttons */}
                        <Grid container spacing={1}>
                            {initialButtons.map((button, index) => (
                                <Grid item xs={6} sm={4} md={2.4} key={index}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            fontSize: { xs: '0.8rem', sm: '1rem' },
                                            padding: { xs: '3px', sm: '5px' }
                                        }}
                                        onClick={() => handleButtonClick(button.label)}
                                    >
                                        {button.label}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>

                        {/* Extra buttons - Visible when showExtraButtons is true */}
                        {showExtraButtons && (
                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                {extraButtons.map((button, index) => (
                                    <Grid item xs={6} sm={4} md={2.4} key={index}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={{
                                                fontSize: { xs: '0.8rem', sm: '1rem' },
                                                padding: { xs: '3px', sm: '5px' }
                                            }}
                                            onClick={() => handleButtonClick(button.label)}
                                        >
                                            {button.label}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        {/* Expand/Collapse button */}
                        <Box sx={{ m: 'auto', display: 'flex', justifyContent: 'center' }} onClick={() => setShowExtraButtons(!showExtraButtons)}>
                            <IconButton>
                                {showExtraButtons ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Box>
                    </FormControl>
                </Grid>

                {/* Color */}
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        sx={{
                            backgroundColor: '#d9e3ef',
                            padding: { xs: 1.5, sm: 2 }, // Reduced padding on mobile
                            borderRadius: 2
                        }}
                    >
                        <FormLabel sx={{
                            paddingBottom: 1,
                            fontSize: { xs: '0.9rem', sm: '1rem' }, // Smaller font on mobile
                            fontWeight: 500
                        }}>
                            Color
                        </FormLabel>

                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    exclusive={purchasepath}
                                    value={filterFormData.color}
                                    onChange={handleColorChange}
                                    aria-label="color selection"
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 0.5,
                                        '& .MuiToggleButtonGroup-grouped': {
                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                            '&:not(:first-of-type)': {
                                                borderRadius: 3,
                                                marginLeft: '0 !important',
                                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                            },
                                            '&:first-of-type': {
                                                borderRadius: 3,
                                            },
                                        },
                                    }}
                                >
                                    {colors.map((color) => (
                                        <ToggleButton
                                            key={color}
                                            value={color}
                                            aria-label={color}
                                            sx={{
                                                backgroundColor: "white",
                                                flex: { xs: '1 0 calc(20% - 8px)', sm: '1 0 calc(10% - 8px)' }, // 5 buttons per row on mobile, 10 on desktop
                                                minWidth: { xs: '40px', sm: '48px' },
                                                minHeight: { xs: '36px', sm: '40px' },
                                                padding: { xs: 0.5, sm: 1 },
                                                borderRadius: '12px !important',
                                                fontSize: { xs: '0.8rem', sm: '0.875rem' },
                                                '&.Mui-selected': {
                                                    backgroundColor: '#1976d2',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#1565c0',
                                                    },
                                                },
                                            }}
                                        >
                                            {color}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={fancyColorsEnabled}
                                            onChange={(e) => {
                                                setFancyColorsEnabled(e.target.checked);
                                                handleFancyColorsChange(e);
                                            }}
                                            name="fancyColorsEnabled"
                                        />
                                    }
                                    label="Enable Fancy Colors"
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: { xs: '0.9rem', sm: '1rem' }
                                        }
                                    }}
                                />
                            </Grid>

                            {fancyColorsEnabled && (
                                <>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>Intensity</InputLabel>
                                            <Select
                                                sx={{
                                                    backgroundColor: "white",
                                                    border: 'none',
                                                    '& .MuiSelect-select': {
                                                        fontSize: { xs: '0.875rem', sm: '1rem' }
                                                    }
                                                }}
                                                value={filterFormData.intensity}
                                                onChange={handleIntensity}
                                                label="Intensity"
                                            >
                                                {Intensity.map((intensity) => (
                                                    <MenuItem
                                                        key={intensity}
                                                        value={intensity}
                                                        sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                                                    >
                                                        {intensity}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>Overtone</InputLabel>
                                            <Select
                                                sx={{
                                                    backgroundColor: "white",
                                                    border: 'none',
                                                    '& .MuiSelect-select': {
                                                        fontSize: { xs: '0.875rem', sm: '1rem' }
                                                    }
                                                }}
                                                value={filterFormData.overtone}
                                                onChange={handleOvertone}
                                                label="Overtone"
                                            >
                                                {fancycolor.map((color) => (
                                                    <MenuItem
                                                        key={color}
                                                        value={color}
                                                        sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                                                    >
                                                        {color}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>Color</InputLabel>
                                            <Select
                                                sx={{
                                                    backgroundColor: "white",
                                                    border: 'none',
                                                    '& .MuiSelect-select': {
                                                        fontSize: { xs: '0.875rem', sm: '1rem' }
                                                    }
                                                }}
                                                value={filterFormData.fancy_color}
                                                onChange={handleFancyColor}
                                                label="Color"
                                            >
                                                {fancycolor.map((color) => (
                                                    <MenuItem
                                                        key={color}
                                                        value={color}
                                                        sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                                                    >
                                                        {color}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </FormControl>
                </Grid>
                {/* Clarity */}
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        sx={{
                            backgroundColor: '#d9e3ef',
                            padding: { xs: 2, sm: 3 }, // Reduced padding on mobile
                            borderRadius: 2
                        }}
                    >
                        <FormLabel sx={{ mb: { xs: 1, sm: 2 } }}>Clarity</FormLabel>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    exclusive={purchasepath ? true : false}
                                    value={filterFormData.clarity}
                                    onChange={handleClarityChange}
                                    aria-label="clarity selection"
                                    fullWidth
                                    sx={{
                                        display: { xs: 'grid', sm: 'flex' }, // Grid on mobile, flex on desktop
                                        gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'none' }, // 4 columns on mobile
                                        gap: { xs: 1, sm: 0 }, // Gap only on mobile
                                        '& .MuiToggleButtonGroup-grouped': {
                                            // Keep desktop styling as is
                                            '@media (min-width: 600px)': {
                                                margin: 0.5,
                                                padding: 1,
                                                borderRadius: 3,
                                            },
                                            // Mobile-specific styling
                                            '@media (max-width: 599px)': {
                                                margin: '0 !important',
                                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                                borderRadius: '8px !important',
                                                '&:not(:first-of-type)': {
                                                    borderRadius: '8px !important',
                                                    borderLeft: '1px solid rgba(0, 0, 0, 0.12) !important',
                                                },
                                            }
                                        }
                                    }}
                                >
                                    {clarities.map((clarity) => (
                                        <ToggleButton
                                            key={clarity}
                                            value={clarity}
                                            aria-label={clarity}
                                            sx={{
                                                backgroundColor: "white",
                                                // Keep desktop styling as is
                                                '@media (min-width: 600px)': {
                                                    margin: 0.5,
                                                    padding: 1,
                                                    borderRadius: 3
                                                },
                                                // Mobile-specific styling
                                                '@media (max-width: 599px)': {
                                                    height: '40px',
                                                    minWidth: '40px',
                                                    padding: '4px 8px',
                                                    fontSize: '0.875rem',
                                                },
                                                '&.Mui-selected': {
                                                    backgroundColor: '#1976d2',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#1565c0',
                                                    },
                                                },
                                            }}
                                        >
                                            {clarity}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Grid>
                {/* Finish */}
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 3, borderRadius: 2 }}>
                        <FormLabel>Finish</FormLabel>
                        <Grid container spacing={1} direction={"column"}>
                            {stockpath && (
                                <Grid item>
                                    <RadioGroup name="finish" row onChange={(event) => handleFinishChange(event.target.value)}>
                                        {finishes.map((finish) => (
                                            <FormControlLabel value={finish} control={<Radio />} label={finish} />
                                        ))}
                                    </RadioGroup>
                                </Grid>
                            )}
                            <Grid container item spacing={1}>
                                <Grid item container xs={12} sm={3} md={3.5} spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined" sx={{
                                                textAlign: 'center',
                                                transform: 'translate(14px, 12px) scale(1)',
                                                '&.MuiInputLabel-shrink': {
                                                    transform: 'translate(14px, -6px) scale(0.75)',
                                                },
                                            }}>Cut From</InputLabel>
                                            <Select size="small" sx={{ backgroundColor: "white", border: 'none' }} label="Cut From" onChange={handleCutFrom} value={cutFrom}>
                                                {Quality.map((range) => (
                                                    <MenuItem key={range} value={range}>{range}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined" sx={{
                                                textAlign: 'center',
                                                transform: 'translate(14px, 12px) scale(1)',
                                                '&.MuiInputLabel-shrink': {
                                                    transform: 'translate(14px, -6px) scale(0.75)',
                                                },
                                            }}>Cut To</InputLabel>
                                            <Select size="small" sx={{ backgroundColor: "white", border: 'none' }} label="Cut to" onChange={handleCutTo} value={cutTo}>
                                                {Quality.map((range) => (
                                                    <MenuItem key={range} value={range}>{range}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Divider
                                        orientation="vertical"
                                        sx={{
                                            height: 46,
                                            mx: 1,
                                            display: { xs: 'none', sm: 'block' }
                                        }}
                                    />
                                </Grid>
                                <Grid item container xs={12} sm={3} md={3.5} spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined" sx={{
                                                textAlign: 'center',
                                                transform: 'translate(14px, 12px) scale(1)',
                                                '&.MuiInputLabel-shrink': {
                                                    transform: 'translate(14px, -6px) scale(0.75)',
                                                },
                                            }}>Polish From</InputLabel>
                                            <Select size="small" sx={{ backgroundColor: "white", border: 'none' }} label="polish From" onChange={handlePolishFrom} value={polishFrom}>
                                                {Quality.map((range) => (
                                                    <MenuItem key={range} value={range}>{range}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined" sx={{
                                                textAlign: 'center',
                                                transform: 'translate(14px, 12px) scale(1)',
                                                '&.MuiInputLabel-shrink': {
                                                    transform: 'translate(14px, -6px) scale(0.75)',
                                                },
                                            }}>Polish To</InputLabel>
                                            <Select size="small" sx={{ backgroundColor: "white", border: 'none' }} label="polish To" onChange={handlePolishTo} value={polishTo}>
                                                {Quality.map((range) => (
                                                    <MenuItem key={range} value={range}>{range}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Divider
                                        orientation="vertical"
                                        sx={{
                                            height: 46,
                                            mx: 1,
                                            display: { xs: 'none', sm: 'block' }
                                        }}
                                    />
                                </Grid>
                                <Grid item container xs={12} sm={3} md={3.5} spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined" sx={{
                                                textAlign: 'center',
                                                transform: 'translate(14px, 12px) scale(1)',
                                                '&.MuiInputLabel-shrink': {
                                                    transform: 'translate(14px, -6px) scale(0.75)',
                                                },
                                            }}>Symmetry From</InputLabel>
                                            <Select size="small" sx={{ backgroundColor: "white", border: 'none' }} label="symmetry From" onChange={handleSymmetryFrom} value={symmetryFrom}>
                                                {Quality.map((range) => (
                                                    <MenuItem key={range} value={range}>{range}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined" sx={{
                                                textAlign: 'center',
                                                transform: 'translate(14px, 12px) scale(1)',
                                                '&.MuiInputLabel-shrink': {
                                                    transform: 'translate(14px, -6px) scale(0.75)',
                                                },
                                            }}>Symmetry To</InputLabel>
                                            <Select size="small" sx={{ backgroundColor: "white", border: 'none' }} label="Symmetry to" onChange={handleSymmetryTo} value={symmetryTo}>
                                                {Quality.map((range) => (
                                                    <MenuItem key={range} value={range}>{range}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        sx={{
                            backgroundColor: '#d9e3ef',
                            padding: { xs: 2, sm: 3 },
                            borderRadius: 2
                        }}
                    >
                        <FormLabel sx={{ mb: { xs: 1, sm: 2 } }}>Fluorescence</FormLabel>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                flexDirection: { xs: 'column', sm: 'row' }  // Stack on mobile, row on desktop
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={9}
                                sx={{ mb: { xs: 2, sm: 0 } }}  // Add margin bottom on mobile
                            >
                                <ToggleButtonGroup
                                    exclusive={purchasepath ? true : false}
                                    value={filterFormData.fluorescence}
                                    onChange={handleColorFluorescence}
                                    aria-label="Fluorescence selection"
                                    fullWidth
                                    sx={{
                                        display: { xs: 'grid', sm: 'flex' }, // Grid on mobile, flex on desktop
                                        gridTemplateColumns: { xs: 'repeat(3, 1fr)', sm: 'none' }, // 3 columns on mobile
                                        gap: { xs: 1, sm: 0 }, // Gap only on mobile
                                        '& .MuiToggleButtonGroup-grouped': {
                                            // Desktop styling
                                            '@media (min-width: 600px)': {
                                                margin: 0.5,
                                                padding: 1,
                                                borderRadius: 3,
                                            },
                                            // Mobile styling
                                            '@media (max-width: 599px)': {
                                                margin: '0 !important',
                                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                                borderRadius: '8px !important',
                                                '&:not(:first-of-type)': {
                                                    borderRadius: '8px !important',
                                                    borderLeft: '1px solid rgba(0, 0, 0, 0.12) !important',
                                                },
                                            }
                                        }
                                    }}
                                >
                                    {Fluorescence.map((fluorescence) => (
                                        <ToggleButton
                                            key={fluorescence}
                                            value={fluorescence}
                                            aria-label={fluorescence}
                                            sx={{
                                                backgroundColor: "white",
                                                // Desktop styling
                                                '@media (min-width: 600px)': {
                                                    margin: 0.5,
                                                    padding: 1,
                                                    borderRadius: 3
                                                },
                                                // Mobile styling
                                                '@media (max-width: 599px)': {
                                                    height: '40px',
                                                    padding: '4px 8px',
                                                    fontSize: '0.875rem',
                                                },
                                                '&.Mui-selected': {
                                                    backgroundColor: '#1976d2',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#1565c0',
                                                    },
                                                },
                                            }}
                                        >
                                            {fluorescence}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={3}
                                sx={{
                                    width: { xs: '100%', sm: 'auto' }  // Full width on mobile
                                }}
                            >
                                <FormControl
                                    fullWidth
                                    size="small"  // Smaller size on mobile
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: { xs: '40px', sm: 'auto' }  // Consistent height with toggle buttons on mobile
                                        }
                                    }}
                                >
                                    <InputLabel sx={{
                                        textAlign: 'center',
                                        transform: {
                                            xs: 'translate(14px, 8px) scale(1)',  // Adjusted for smaller size on mobile
                                            sm: 'translate(14px, 12px) scale(1)'
                                        },
                                        '&.MuiInputLabel-shrink': {
                                            transform: {
                                                xs: 'translate(14px, -6px) scale(0.75)',
                                                sm: 'translate(14px, -6px) scale(0.75)'
                                            },
                                        },
                                    }}>
                                        Color
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            backgroundColor: "white",
                                            border: 'none',
                                        }}
                                        label="Color"
                                        onChange={handleFluorescence}
                                        value={filterFormData.fluorescence_color || ''}  // Added default value
                                    >
                                        {fancycolor.map((color) => (
                                            <MenuItem
                                                key={color}
                                                value={color}
                                                sx={{
                                                    fontSize: { xs: '0.875rem', sm: '1rem' }  // Smaller font on mobile
                                                }}
                                            >
                                                {color}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 3, borderRadius: 2 }}>
                        <FormLabel>Location</FormLabel>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Select value={filterFormData.location} sx={{ backgroundColor: "white", border: 'none' }}
                                        onChange={handleCountryChange} displayEmpty >
                                        <MenuItem value="" disabled>
                                            Select a country
                                        </MenuItem>
                                        {diamondTradingCountries.map((country) => (
                                            <MenuItem key={country} value={country}>
                                                {country}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 3, borderRadius: 2 }}>
                        <FormLabel>Weight (carats)</FormLabel>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={filterFormData.weight}
                                        onChange={handleWeightChange}
                                        inputProps={{ min: 0, step: 0.01 }}
                                        placeholder="Enter diamond weight"
                                        sx={{ backgroundColor: "white", border: 'none', borderRadius: 1 }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        sx={{
                            backgroundColor: '#d9e3ef',
                            padding: { xs: 2, sm: 3 },
                            borderRadius: 2,
                            mb: 2
                        }}
                    >
                        <FormLabel sx={{ mb: { xs: 1, sm: 2 } }}>Lab Reports</FormLabel>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    exclusive={purchasepath ? true : false}
                                    value={filterFormData.lab_reports}
                                    onChange={handleLabChange}
                                    aria-label="lab selection"
                                    fullWidth
                                    sx={{
                                        display: { xs: 'grid', sm: 'flex' }, // Grid on mobile, flex on desktop
                                        gridTemplateColumns: { xs: 'repeat(3, 1fr)', sm: 'none' }, // 3 columns on mobile
                                        gap: { xs: 1, sm: 0 }, // Gap only on mobile
                                        '& .MuiToggleButtonGroup-grouped': {
                                            // Desktop styling
                                            '@media (min-width: 600px)': {
                                                margin: 0.5,
                                                padding: 0.5,
                                                borderRadius: 3,
                                            },
                                            // Mobile styling
                                            '@media (max-width: 599px)': {
                                                margin: '0 !important',
                                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                                borderRadius: '8px !important',
                                                '&:not(:first-of-type)': {
                                                    borderRadius: '8px !important',
                                                    borderLeft: '1px solid rgba(0, 0, 0, 0.12) !important',
                                                },
                                            }
                                        }
                                    }}
                                >
                                    {LabReports.map((lab) => (
                                        <ToggleButton
                                            key={lab}
                                            value={lab}
                                            aria-label={lab}
                                            sx={{
                                                backgroundColor: "white",
                                                // Desktop styling
                                                '@media (min-width: 600px)': {
                                                    margin: 0.5,
                                                    padding: 0.5,
                                                    borderRadius: 3
                                                },
                                                // Mobile styling
                                                '@media (max-width: 599px)': {
                                                    height: '40px',
                                                    padding: '4px 8px',
                                                    fontSize: '0.875rem',
                                                },
                                                '&.Mui-selected': {
                                                    backgroundColor: '#1976d2',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#1565c0',
                                                    },
                                                },
                                            }}
                                        >
                                            {lab}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
                        <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
                            <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
                                {stockpath ? <Button variant="contained" color="primary" onClick={handleSubmit}>
                                    Filter
                                </Button> : <>
                                    <Button variant="contained" color="primary" onClick={handleRap}>
                                        <AddIcon sx={{ marginRight: 1 }} />
                                        Add rap
                                    </Button>
                                </>
                                }
                                <Button variant="outlined" color="primary" onClick={handleReset}>
                                    Reset
                                </Button>
                            </Box>

                        </Box>

                        {/* {stockpath && fetchedData && fetchedData.map((data: any, index: any) => (
                            <StockDataCard key={index} stockData={data} />
                        ))} */}
                        {isFilterLoading ? (

                            Array.from({ length: 5 }).map((_, index) => (
                                <SkeletonData key={index} />
                            ))
                        ) : fetchedData ? (
                            fetchedData.map((data: any, index: React.Key | null | undefined) => (
                                <StockDataCard key={index} stockData={data} />
                            ))
                        ) : (
                            <>
                                {setOnSuccess({ success: true, message: "No data available.", variant: 'error' })}
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FilterForm;
