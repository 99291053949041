import React from "react";
import { useSelector } from "react-redux";
import {
    Container,
    Typography,
    Box,
    Grid,
    Card,
    CardContent,
    Avatar,
    Divider,
    useMediaQuery,
    useTheme,
    styled,
} from "@mui/material";
import {
    Person as PersonIcon,
    Phone as PhoneIcon,
    Email as EmailIcon,
    Business as BusinessIcon,
    LocationOn as LocationOnIcon,
    Work as WorkIcon,
    Group as GroupIcon,
} from "@mui/icons-material";
import { RootState } from "../../store/store";

const StyledInfoItem = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
}));

const Profile: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const user = useSelector((state: RootState) => state.user.data);

    if (!user) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Container maxWidth="lg" sx={{ py: 10 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card elevation={3} sx={{ height: "100%" }}>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <Avatar
                                sx={{ width: 80, height: 80, mb: 2, bgcolor: "primary.main" }}
                            >
                                <PersonIcon sx={{ fontSize: 60 }} />
                            </Avatar>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >{`${user.first_name} ${user.last_name}`}</Typography>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                {user.user_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {user.occupation ? user.occupation.join(', ') : 'N/A'}
                            </Typography>


                            <Typography variant="body2" color="textSecondary">
                                {user.company_name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card elevation={3}>
                        <CardContent>
                            <Typography sx={{ py: 1 }} variant="h6" gutterBottom>
                                Personal Information
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <PhoneIcon />
                                        </IconWrapper>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                Phone Number
                                            </Typography>
                                            <Typography variant="body1">
                                                {user.phone_number}
                                            </Typography>
                                        </Box>
                                    </StyledInfoItem>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <EmailIcon />
                                        </IconWrapper>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                Email
                                            </Typography>
                                            <Typography variant="body1">{user.email}</Typography>
                                        </Box>
                                    </StyledInfoItem>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <LocationOnIcon />
                                        </IconWrapper>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                Country
                                            </Typography>
                                            <Typography variant="body1">{user.country}</Typography>
                                        </Box>
                                    </StyledInfoItem>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <BusinessIcon />
                                        </IconWrapper>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                Company Address
                                            </Typography>
                                            <Typography variant="body1">
                                                {user.company_address}
                                            </Typography>
                                        </Box>
                                    </StyledInfoItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box mt={4}>
                {user.partners && user.partners.length > 0 && (
                    <Typography variant="h6" gutterBottom>
                        Partners
                    </Typography>
                )}

                <Grid container spacing={3}>
                    {user.partners?.map((partner, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card elevation={3}>
                                <CardContent>
                                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                        <Avatar sx={{ bgcolor: "secondary.main", mr: 2 }}>
                                            <GroupIcon />
                                        </Avatar>
                                        <Typography variant="h6">{`${partner.first_name} ${partner.last_name}`}</Typography>
                                    </Box>
                                    <Divider sx={{ mb: 2 }} />
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <EmailIcon />
                                        </IconWrapper>
                                        <Typography variant="body2">{partner.email}</Typography>
                                    </StyledInfoItem>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <PhoneIcon />
                                        </IconWrapper>
                                        <Typography variant="body2">
                                            {partner.phone_number}
                                        </Typography>
                                    </StyledInfoItem>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};

export default Profile;
