import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button } from "@mui/material";
import { DiamondDataGrid } from "../../Component/DiamondDataGrid";
import FilterForm from "../../Component/FilterForm";

const Stock: React.FC = () => {
    const [gridColumns, setGridColumns] = useState([]);
    const [data, setData] = useState([]);



    // useEffect(() => {
    //     // Fetch data from the backend API
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.post('/stock/view-stock');
    //             const fetchedData = response.data;

    //             if (fetchedData.length > 0) {

    //                 const columns = Object.keys(fetchedData[0])
    //                     .filter(key => key !== "_id" && key !== "id" && key !== "__v")
    //                     .map(key => ({
    //                         field: key,
    //                         headerName: key.replace(/_/g, ' ').toUpperCase(),
    //                         width: 150,
    //                     }));

    //                 setGridColumns(columns);
    //                 setData(fetchedData);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>

            <FilterForm />
            {/* <Box display="flex" gap={2}>
                <Button variant="contained" color="primary">
                    Search
                </Button>
                <Button variant="outlined" color="primary">
                    Reset
                </Button>
            </Box> */}
            {/* <DiamondDataGrid columns={gridColumns} rows={data} title={'Stocked Data'} initialPageSize={10} /> */}
        </Box>
    );
};

export default Stock;
